import logo from './../logo.svg';
import './FrontPage.scss';

function App() {
  const sayHello = () => {
    alert("Hello\nHow are you?");
  }
  return (
    <div className="App fp-container">
        <header className="fp-navbar">
            <img src={logo} className="fp-logo" alt="logo" />
            <div className="fp-hello-btn" onClick={()=> sayHello()}>Say Hello</div>
        </header>
        <div className="fp-cover-text header">
          <div className="bright-text header">
            FullStack Developer
          </div>
          <p className="light-text fp-sub-head">
            I build tech products, and I love what I do.
          </p>
          <p className="light-text fp-sub-head">
            Hi, I'm Chitrarth. Nice to meet you. This site is under construction 🚧, will have unexpected behaviour!😃<br/>
            I build stuff. Really good in conceptualizing and building MVP! Congrats on landing here!!
          </p>
      </div>
    </div>
  );
}

export default App;
